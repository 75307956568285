import React from "react";
import { Button } from "@shopify/polaris";
import { IUser } from "../lib/API";

interface Props {
  userdata: IUser;
}

export default function Settings(props: Props) {
  const file = new Blob([JSON.stringify(props.userdata)], {
    type: "text/plain"
  });
  const url = URL.createObjectURL(file);
  return (
    <Button url={url} download={"mike.txt"}>
      Download backup file
    </Button>
  );
}
