import React, { useState } from "react";
import { Page, AppProvider } from "@shopify/polaris";

export interface Props {
  onUnlock: () => void;
}

export default function Home(props: Props) {
  const [clicked, setClicked] = useState<number>(0);

  if (clicked === 3) {
    props.onUnlock();
  }

  return (
    <AppProvider>
      <div onClick={() => setClicked(clicked + 1)}>
        <Page title="Mike Rybka" />
      </div>
    </AppProvider>
  );
}
