export interface IUser {
  admin: boolean;
  functions: { [key: string]: IFunction };
}

const newUser: IUser = {
  admin: false,
  functions: {}
};

function setUser(u: IUser) {
  localStorage.setItem("user", JSON.stringify(u));
}

export function getUser(): IUser {
  const u = localStorage.getItem("user");
  if (!u) {
    localStorage.setItem("user", JSON.stringify(newUser));
    return newUser;
  }
  return JSON.parse(u);
}

interface IFunction {
  name: string;
}

export function setFunction(id: string, f: IFunction) {
  const u = getUser();
  u.functions[id] = f;
  setUser(u);
}
