import React, { useState } from "react";
import Home from "./components/Home";
import PolarisAppCreator from "./views/PolarisAppCreator";
import Settings from "./views/Settings";
import {
  PageDownMajorMonotone,
  AppsMajorMonotone
} from "@shopify/polaris-icons";
import { getUser } from "./lib/API";
import AppShell from "./components/AppShell";

export default function App() {
  const [unlocked, setUnlocked] = useState<boolean>(isUnlocked());

  if (unlocked) {
    return (
      <AppShell
        onSignOut={() => {
          setUnlocked(false);
          lock();
        }}
        pages={[
          {
            title: "App creator",
            icon: AppsMajorMonotone,
            content: (
              <PolarisAppCreator
                data={{}}
                store={(key, value) =>
                  Promise.resolve({ success: false, text: "" })
                }
              />
            )
          },
          {
            title: "Settings",
            icon: PageDownMajorMonotone,
            content: <Settings userdata={getUser()} />
          }
        ]}
      />
    );
  } else {
    return (
      <Home
        onUnlock={() => {
          setUnlocked(true);
          unlock();
        }}
      />
    );
  }
}

function unlock() {
  localStorage.setItem("unlocked", JSON.stringify(true));
}

function isUnlocked() {
  const unlocked = localStorage.getItem("unlocked");
  return unlocked && JSON.parse(unlocked);
}

function lock() {
  localStorage.removeItem("unlocked");
}
