import React, { useState } from "react";
import { TextField, Button, Select, Card, Stack } from "@shopify/polaris";

export type Props = {
  data: {};
  store: (key: string, value: string) => Promise<SimpleRequest>;
};

type SimpleRequest = {
  success: boolean;
  text: string;
};

type Component = "text" | "div";
const COMPONENTS = ["text", "div"];

type View = {
  component: Component;
  props: ViewProps;
};

type ViewProps = {
  text: string;
  children: Array<View>;
};
const defaultViewProps: ViewProps = {
  text: "",
  children: []
};
function newViewProps(props: ViewProps, diff: Object): ViewProps {
  return Object.assign({}, props, diff);
}

export default function PolarisAppCreator(props: Props) {
  const [editorState, setEditorState] = useState<View>({
    component: "div",
    props: defaultViewProps
  });

  console.log(editorState);

  return (
    <div>
      <Card sectioned title="Live view">
        <LiveView value={editorState} />
      </Card>
      <Card sectioned title="Editor">
        <ViewEditor
          value={editorState}
          onChange={e => setEditorState(e)}
          onDelete={() => {}}
        />
      </Card>
    </div>
  );
}

function LiveView(props: { value?: View }) {
  const view = props.value;
  if (!view) return null;
  if (view.component === "div") {
    return (
      <div>
        {view.props.children.map((v, i) => (
          <LiveView key={i} value={v} />
        ))}
      </div>
    );
  } else if (view.component === "text") {
    return <p>{view.props.text}</p>;
  }

  return <p>ERROR</p>;
}

function ViewEditor(props: {
  value: View;
  onChange: (v: View) => void;
  onDelete: () => void;
}) {
  const self = props.value;
  const setSelf = props.onChange;
  const deleteSelf = props.onDelete;

  return (
    <Card>
      <Card.Section>
        <Stack>
          <Select
            label="Component type"
            labelHidden
            options={COMPONENTS.map(c => ({ label: c, value: c }))}
            onChange={(c: Component) => {
              setSelf({
                component: c,
                props: self.props
              });
            }}
            value={self.component}
          />
          <Button onClick={deleteSelf}>Delete</Button>
        </Stack>
      </Card.Section>
      <EditProps
        component={self.component}
        value={self.props}
        onChange={(p: ViewProps) => {
          setSelf({
            component: self.component,
            props: p
          });
        }}
      />
    </Card>
  );
}

function EditProps(props: {
  component: Component;
  value: ViewProps;
  onChange: (p: ViewProps) => void;
}) {
  const viewProps = props.value;
  const setViewProps = props.onChange;

  if (props.component === "div") {
    return <EditDiv value={viewProps} onChange={setViewProps} />;
  } else if (props.component === "text") {
    return <EditText value={viewProps} onChange={setViewProps} />;
  } else {
    return null;
  }
}

function EditDiv(props: {
  value: ViewProps;
  onChange: (v: ViewProps) => void;
}) {
  const viewProps = props.value;
  const setViewProps = props.onChange;

  return (
    <>
      <Card.Section>
        <p>
          Children:{" "}
          <Button
            onClick={() =>
              setViewProps({
                ...viewProps,
                children: [
                  ...viewProps.children,
                  { component: "text", props: defaultViewProps }
                ]
              })
            }
          >
            Add
          </Button>
        </p>
      </Card.Section>
      {viewProps.children.map((child, i) => (
        <Card.Section key={i}>
          <ViewEditor
            value={child}
            onChange={v => {
              setViewProps(
                newViewProps(viewProps, {
                  children: [
                    ...viewProps.children.slice(0, i),
                    v,
                    ...viewProps.children.slice(i + 1)
                  ]
                })
              );
            }}
            onDelete={() =>
              setViewProps(
                newViewProps(viewProps, {
                  children: [
                    ...viewProps.children.slice(0, i),
                    ...viewProps.children.slice(i + 1)
                  ]
                })
              )
            }
          />
        </Card.Section>
      ))}
    </>
  );
}

function EditText(props: {
  value: ViewProps;
  onChange: (p: ViewProps) => void;
}) {
  const viewProps = props.value;
  const setViewProps = props.onChange;
  return (
    <Card.Section>
      <TextField
        label="text"
        labelHidden
        value={viewProps.text}
        onChange={t => {
          setViewProps(
            newViewProps(viewProps, {
              text: t
            })
          );
        }}
      />
    </Card.Section>
  );
}
