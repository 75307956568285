import React, { useState } from "react";
import { Frame, TopBar, Navigation, AppProvider, Page } from "@shopify/polaris";

export interface Props {
  pages: Array<{
    title: string;
    icon?: any;
    content: any;
  }>;
  onSignOut: () => void;
}

export default function AppShell(props: Props) {
  const [page, setPage] = useState<number>(0);
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [showMobileNavigation, setShowMobileNavigation] = useState<boolean>(
    false
  );

  const openPage = props.pages[page];

  return (
    <AppProvider
      theme={{
        colors: {
          topBar: {
            background: "#357997"
          }
        }
      }}
    >
      <Frame
        topBar={
          <TopBar
            showNavigationToggle={true}
            userMenu={
              <TopBar.UserMenu
                actions={[
                  {
                    items: [{ content: "Sign out", onAction: props.onSignOut }]
                  }
                ]}
                name="Mike"
                detail={"Admin"}
                initials="MR"
                open={userMenuOpen}
                onToggle={() => setUserMenuOpen(!userMenuOpen)}
              />
            }
            onNavigationToggle={() =>
              setShowMobileNavigation(!showMobileNavigation)
            }
          />
        }
        navigation={
          <Navigation location="/">
            <Navigation.Section
              items={props.pages.map((p, i) => {
                return {
                  label: p.title,
                  icon: p.icon,
                  onClick: () => setPage(i)
                };
              })}
            />
          </Navigation>
        }
        showMobileNavigation={showMobileNavigation}
        onNavigationDismiss={() =>
          setShowMobileNavigation(!showMobileNavigation)
        }
      >
        <Page title={openPage.title}>{openPage.content}</Page>
      </Frame>
    </AppProvider>
  );
}
